import { useAppSelector } from "../../app/redux/store";
import {
  IdentityAssessmentResponse,
  selectIdentityAssessment,
} from "../../app/identityAssessment/identityAssessment.slice";
import { Logger } from "../../utils/common/logger";
import { PdfFirstPage } from "../../components/pdf/pdf.firstPage";
import EnvironmentConfig from "../../utils/environmentConfig";
import "../../assets/styles/App.css";

export function IdentityAssessmentFirstPage() {
  const identityAssessment: IdentityAssessmentResponse = useAppSelector(
    selectIdentityAssessment
  );

  if (
    identityAssessment.status == "idle" ||
    identityAssessment.status == "loading"
  ) {
    return null
  }

  {
    EnvironmentConfig.isDevelopment && Logger.log(identityAssessment.data);
  }

  return <PdfFirstPage />;
}
