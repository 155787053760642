import "./assets/styles/App.css";
import { IdentityAssessmentForm } from "./pages/IdentityAssessment.form";
import useIdentityAssessment from "./app/identityAssessment/hooks/useIdentityAssessment";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IdentityAssessmentFirstPage } from "./pages/pdf/IdentityAssessment.firstPage";
import { IdentityAssessmentSecondPage } from "./pages/pdf/identityAssessment.secondPage";
import { IdentityAssessmentThirdPage } from "./pages/pdf/IdentityAssessment.thirdPage";
import { IdentityAssessmentRepeatingPage } from "./pages/pdf/IdentityAssessment.repeatingPage";
import { IdentityAssessmentFinalPage } from "./pages/pdf/IdentityAssessment.finalPage";
import useAnalytics from "./app/identityAssessment/hooks/useGoogleAnalytics";
import { useAppSelector } from "./app/redux/store";
import { selectIdentityAssessment } from "./app/identityAssessment/identityAssessment.slice";
import LoadingSpinner from "./components/ui/loadingSpinner";
import { useState, useEffect } from "react";
import { QuestionStep } from "./app/identityAssessment/types/identityAssessment.types";
import { LazyImage } from "./components/ui/lazyImage";

function App() {
  useIdentityAssessment();
  useAnalytics();
  const identityAssessment = useAppSelector(selectIdentityAssessment);
  const backgroundImage = identityAssessment.data?.design.background;
  const [routes, setRoutes] = useState([]);
  const filteredCategories: any = identityAssessment.data?.categories;
  let questionListAll: Array<QuestionStep> = identityAssessment.data?.questionSteps || [];

  useEffect(() => {
    //Dynamic routes for all questions
    const filteredQuestions = questionListAll.filter((question) => {
      if (question.segment.toLowerCase() === identityAssessment.segment.toLowerCase()) return question;
      if (!identityAssessment.segment) return question;
    });
    let routes:any = [];
    if(filteredCategories) {
      filteredCategories?.map((category, index) => {
        const categoryQuestions = filteredQuestions.filter(question => question.category === category)
        categoryQuestions.forEach((q, questionIndex) => {
              routes.push(<Route
                key={`${category}-${questionIndex}`}
                path={`/pdf/categoryPage${index}-${questionIndex}`}
                element={<IdentityAssessmentRepeatingPage category={category} />}
              />)
        })
        return routes
      })
      setRoutes(routes)
    }
  }, [filteredCategories]);

  return (
    <div className="App">
      <LazyImage className="lazy-background" src={backgroundImage} isBackgroundIage={true} />
      {identityAssessment.status === "loading" && (
        <LoadingSpinner />
      )}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IdentityAssessmentForm />} />
          <Route path="/pdf/firstPage" element={<IdentityAssessmentFirstPage />} />
          <Route path="/pdf/secondPage" element={<IdentityAssessmentSecondPage />} />
          <Route path="/pdf/thirdPage" element={<IdentityAssessmentThirdPage />} />
          {routes}
          <Route path="/pdf/finalPage" element={<IdentityAssessmentFinalPage />} />
          <Route path="*" element={<IdentityAssessmentForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
