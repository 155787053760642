import EnvironmentConfig from "../environmentConfig";

export class Logger {
  static log(...args: any[]) {
    if (EnvironmentConfig.isDevelopment) {
      // console.log(args);
    }
  }
  
  static error(object:any, message: string) {
    /*
      Log error to the monitoring tool
    */
    Logger.log(object, message);
  }
}

export enum ErrorTypes
{
  "question-not-found" = "question-not-found",
  "answere-not-found" = "answere-not-found",
  "identity-assessment-get-content-failed" = "identity-assessment-get-content-failed",
  "generate-pdf-failed" = "generate-pdf-failed"
}