import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { getContent } from '../thunks/getContent.thunks'

const useIdentityAssessment = () => {
    const dispatch = useAppDispatch()
    const { identityAssessment } = useAppSelector((s) => ({
      identityAssessment: s.identityAssessment,
    }))
    
    useEffect(() => {
      if (identityAssessment.status == "idle") {
        dispatch(getContent())
        return
      }
    }, [dispatch, identityAssessment])
  }
  
  export default useIdentityAssessment