import styles from "./styles/pdf.module.css";

export function PdfFirstPage() {
  const data = JSON.parse(localStorage.getItem("data")!);
  const urlParams = new URLSearchParams(window.location.search);
  const name = data.customerData.firstName
  const lastName = data.customerData.lastName
  const company = data.customerData.organization
  const pdfSize = data.pdfSize

  const date = urlParams.get("date") || "undefined (datey)";
  let dateClass = "date";

  if (name.length > 40 && lastName.length > 40 && company.length > 40) dateClass = "date-small-padding";

  return (
    <div className={`${styles["first-page"]} ${styles[pdfSize]}`}>
      <div className={styles["cover-text"]}>
        <div className={styles["cover-title"]}>Prepared for:</div>
        <div id={styles["name"]}>
          {`${name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase()} ${
            lastName.charAt(0).toUpperCase() + lastName.slice(1).toLocaleLowerCase()
          }`}
          <br></br>
          {company.charAt(0).toUpperCase() + company.slice(1).toLocaleLowerCase()}
        </div>
        <div id={styles[dateClass]}>
          Date: <b>{date}</b>
        </div>
      </div>
    </div>
  );
}
