import { useAppSelector, useAppDispatch } from "../../app/redux/store";
import {
  selectIdentityAssessment,
  saveAnswer,
  nextStep,
  prevStep,
  saveError,
  saveResults,
  setCategories,
  saveCustomerData,
  saveSegmentSelector
} from "../../app/identityAssessment/identityAssessment.slice";
import { GetLabel } from "../../utils/helpers/labelHelper";
import { QuestionStep } from "../../app/identityAssessment/types/identityAssessment.types";
import { IdentityAssessmentResponse } from "../../app/identityAssessment/identityAssessment.slice";
import styles from "./styles/IdentityAssessment.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { useSwiper } from "swiper/react";
import { useEffect, useState } from "react";
import { ResultService } from "../../app/identityAssessment/services/identityAssessment.result.service";

const AnswerButton = (props) => {
  const swiper = useSwiper();
  const isChecked = props.currentQuestionResult?.answareId === props.answer.id;
  return (
    <label className={styles.answer}>
      <input
        type="radio"
        name="question"
        value={`${props.answer.id}`}
        checked={isChecked}
        onChange={() => props.selectAnswer(props.answer, swiper)}
      />
      <span className={styles["question-answer-text"]}>{props.answer.text}</span>
    </label>
  );
};

const PreviousButton = (props) => {
  const swiper = useSwiper();

  return (
    <button
      className={`${styles.button} ${styles["question-previous-button"]}`}
      onClick={() => props.previousStep(swiper)}
    >
      <svg viewBox="0 0 50 80">
        <polyline
          fill="none"
          stroke="#367DA9"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="
	45.63,75.8 0.375,38.087 45.63,0.375 "
        />
      </svg>
      {GetLabel(props.identityAssessment.data?.dictionaries!, "previous_question").toUpperCase()}
    </button>
  );
};

const NextButton = (props) => {
  const swiper = useSwiper();
  let disableButton = props.identityAssessment.data?.error.questionError;

  return (
    <button
      disabled={disableButton}
      className={`${styles.button} ${styles["question-next-button"]}`}
      onClick={() => props.nextStep(swiper)}
    >
      {GetLabel(
        props.identityAssessment.data?.dictionaries!,
        props.lastQuestion ? "view_score" : "next_question"
      ).toUpperCase()}
    </button>
  );
};

export function IdentityAssessmentQuestions(props) {
  const dispatch = useAppDispatch();
  const identityAssessment: IdentityAssessmentResponse = useAppSelector(selectIdentityAssessment);

  const [questionContainerHeight, setQuestionContainerHeight] = useState(props.initialHeight);

  const [questionList, setQuestionList] = useState(identityAssessment.data?.questionSteps || []);

  let questionListAll: Array<QuestionStep> = identityAssessment.data?.questionSteps || [];

  const currentQuestion = questionList.find((x) => x.order == identityAssessment.currentStep);

  let currentQuestionResult = identityAssessment.data?.result.results.find((x) => x.questonId == currentQuestion?.id);
  const answer = currentQuestionResult?.answareId;
  const segment = identityAssessment.segment;

  useEffect(() => {
    currentQuestionResult = identityAssessment.data?.result.results.find((x) => x.questonId == currentQuestion?.id);
  }, [answer]);

  // FILTERED QUESTIONS DEPENDING ON THE SELECTED SEGMENT
  useEffect(() => {
    const filteredQuestions = questionListAll.filter((question) => {
      if (question.isGeneral && question.id !== identityAssessment.excludeQuestionId) return question;
      if (question.segment.toLowerCase() === identityAssessment.segment.toLowerCase()) return question;
      if (!identityAssessment.segment) return question;
    });
    let categories: string[] = [];
    filteredQuestions.map((question) => {
      if (!categories.includes(question.category) && question.category !== "Segmentation")
        categories.push(question.category);
    });
    dispatch(setCategories(categories));
    setQuestionList(filteredQuestions);
    props.setSegment(filteredQuestions);
  }, [segment]);

  useEffect(() => {
    let questionIndex = identityAssessment.currentStep === 0 ? "first-page" : currentQuestion?.identifier;
    if (identityAssessment.currentStep > questionList.length) questionIndex = "result-page";
    window.history.pushState("", "", `/#${questionIndex}`);

    if (identityAssessment.data?.result.results.find((res) => res.questonId === currentQuestion?.id))
      dispatch(saveError({ name: "questionError", value: false }));
    else dispatch(saveError({ name: "questionError", value: true }));

    if (currentQuestion?.imageUrl.length) {
      props.resizeComponent(true);
    } else {
      props.resizeComponent(false);
    }
    document.querySelector(".swiper")!.scrollTo(0, 0);
    setContainerHeight();
  }, [currentQuestion]);

  const onSelectAnswer = (answer) => {
    if(answer.segmentSelector) dispatch(saveSegmentSelector({segment_selector:answer.segmentSelector, exclude_question_id: identityAssessment.excludeQuestionId}))
    dispatch(
      saveAnswer({
        ...answer,
        isGeneralQuestion: currentQuestion?.isGeneral,
        questionIdentifier: currentQuestion?.identifier,
      })
    );
  };

  const onPreviousStep = (swiper) => {
    if (swiper.realIndex === 0) {
      props.showUserSlide();
      dispatch(prevStep());
    }
    swiper.slidePrev();
  };

  const onNextStep = (swiper) => {
    if (!currentQuestion?.answers.length) {
      swiper.slideNext();
    } else {
      const answer = identityAssessment.data!.result.results
        ? identityAssessment.data!.result.results.find((q) => q.questonId === currentQuestion?.id)
        : null;

      if (answer && identityAssessment.currentStep > questionList.length - 1) {
        if (swiper.realIndex === questionList.length - 1) {
          let result = ResultService.CalculateScore(identityAssessment.data!);
          dispatch(saveResults(result));
          dispatch(saveCustomerData({ termsAndPrivacy: true })); //if user click submit button he/she agrees with the privacy policy of Imprivata
          props.finalQuestion();
        }
        dispatch(nextStep(currentQuestion));
      } else if (answer) {
        swiper.slideNext();
      } else {
        dispatch(nextStep(currentQuestion));
      }
    }
  };

  const setContainerHeight = () => {
    let questionContentHeight = document.querySelector<any>(`#question-content-${currentQuestion?.id}`);
    const currentQuestionHeight = currentQuestion
      ? (questionContentHeight?.getBoundingClientRect().height || 0) + 6 * 16
      : "auto";
    let containerHeight = identityAssessment.currentStep === 0 ? props.initialHeight : currentQuestionHeight;
    props.setQuestionBlockHeight(containerHeight);
    setQuestionContainerHeight(containerHeight);
  };

  const changedIndex = (swiper) => {
    let question = questionList.find((x) => x.order == swiper.realIndex);
    const isIntroductionSlide = !question?.answers.length;
    if (swiper.swipeDirection === "next" && !isIntroductionSlide) {
      const answer = identityAssessment.data!.result.results
        ? identityAssessment.data!.result.results.find((q) => q.questonId === question?.id)
        : null;

      if (!answer) {
        swiper.slidePrev();
        return;
      }
    }
    // when last slide is reached -> slider should be disabled
    if (swiper.realIndex === questionList.length - 1) swiper.allowSlideNext = false;
  };

  const moveToPrevious = (swiper) => {
    swiper.allowSlideNext = true;
    if (swiper.swipeDirection !== "next") dispatch(prevStep());
  };

  const moveNext = () => {
    dispatch(nextStep(currentQuestion));
  };

  const questionTextClass = currentQuestion?.answers.length ? "question-box-text" : "question-box-text-small";
  const questions: any = questionList.map((currentQuestion, index) => {
    let category = currentQuestion?.categoryLabel?.toUpperCase();
    return (
      <SwiperSlide
        id={`swiper-slider-container-${currentQuestion.id}`}
        key={currentQuestion.id}
        className={styles.slider}
      >
        <div
          id={`question-${currentQuestion.id}`}
          key={currentQuestion.id}
          className={`${styles["question-container"]} ${
            styles[`question-container-${currentQuestion.imageAlignment}`]
          }`}
        >
          <div className={styles["question-box"]} id={`question-box-${currentQuestion?.id}`}>
            <div className={styles["question-content"]} id={`question-content-${currentQuestion?.id}`}>
              <div className={styles["question-category-label"]} dangerouslySetInnerHTML={{ __html: category }}></div>
              <div className={styles[`${questionTextClass}`]}>
                <div dangerouslySetInnerHTML={{ __html: currentQuestion!.text }} />
              </div>
              <div
                className={styles["question-answers-container"]}
                id={`question-answers-cintainer-${currentQuestion?.id}`}
              >
                {currentQuestion!.answers.map((answer) => (
                  <div key={`${answer.id}`}>
                    {answer.type === "radio" && (
                      <AnswerButton
                        currentQuestionResult={currentQuestionResult}
                        answer={answer}
                        selectAnswer={onSelectAnswer}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={styles["question-navigation-buttons"]}
              id={`question-navigation-buttons-${currentQuestion?.id}`}
            >
              <div className={styles["question-navigation-buttons-container"]}>
                <PreviousButton previousStep={onPreviousStep} identityAssessment={identityAssessment} />
                <NextButton
                  lastQuestion={questionList.length - 1 === index}
                  nextStep={onNextStep}
                  identityAssessment={identityAssessment}
                  isIntroductionSlide={!currentQuestion?.answers.length ? true : false}
                />
              </div>
            </div>
          </div>
          {/*TODO - SHOULD CHECK IF QUESTION HAS PICTURE*/}
          {currentQuestion?.imageUrl && (
            <div
              className={styles.picture} //imageAlignment
              style={{
                backgroundImage: `url(${currentQuestion.imageUrl})`,
              }}
            ></div>
          )}
        </div>
      </SwiperSlide>
    );
  });

  const onKeyUp = (event) => {};

  return (
    <div onKeyUp={onKeyUp} style={{ height: "100%" }}>
      <Swiper
        className={styles["swiper-container-question"]}
        style={{ height: questionContainerHeight }}
        slidesPerView={1}
        spaceBetween={50}
        onResize={() => {
          setContainerHeight();
        }}
        threshold={20}
        onSlideNextTransitionStart={moveNext}
        onSlidePrevTransitionStart={moveToPrevious}
        onRealIndexChange={changedIndex}
      >
        {identityAssessment.generatePdfStatus !== "loading" && questions}
      </Swiper>
    </div>
  );
}
