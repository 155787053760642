interface EnvironmentConfig {
  environmentName: string,
  isDevelopment: boolean,
  usAssessment: string,
  intlAssessment: string,
  pdfGenerateUrl: string,
}

function getEnvironmentConfig() : EnvironmentConfig {
  const environmentVariables: EnvironmentConfig = {
    environmentName: process.env.REACT_APP_APP_NAME || '',
    isDevelopment: process.env.REACT_APP_IS_DEVELOPMENT == "true" || false,
    usAssessment: process.env.REACT_APP_US_ASSESSMENT || '',
    intlAssessment: process.env.REACT_APP_INTL_ASSESSMENT || '',
    pdfGenerateUrl: process.env.REACT_APP_PDF_PAGE_GENERATE_URL || '',
  }

  if (!environmentVariables.environmentName 
    || !environmentVariables.usAssessment
    || !environmentVariables.intlAssessment
    || !environmentVariables.pdfGenerateUrl) {
     throw new Error('Environment variables were not properly configured')
  }

  return environmentVariables;
}

const EnvironmentConfig = getEnvironmentConfig();

export default EnvironmentConfig