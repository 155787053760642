import ReactGA from "react-ga4";
import {useEffect} from 'react'
import { useAppSelector } from '../../redux/store'


const useAnalytics = () => {
  const {identityAssessment} = useAppSelector((s) => ({
    identityAssessment: s.identityAssessment,
  }))
  const location = window.location.pathname
  const trackingId = identityAssessment.data?.ga.trackingId || 'G-YVBVS78LS2'

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location })
  },[location])

  useEffect(() => {
    ReactGA.initialize(trackingId)
  },[trackingId])
}

export default useAnalytics