import { useAppSelector } from "../../app/redux/store";
import { selectIdentityAssessment } from "../../app/identityAssessment/identityAssessment.slice";
import { QuestionaryOutcome } from "../../app/identityAssessment/types/identityAssessment.types";
import { IdentityAssessmentResponse } from "../../app/identityAssessment/identityAssessment.slice";
import { ResultService } from "../../app/identityAssessment/services/identityAssessment.result.service";
import { DownloadButton } from "../ui/downloadButton";
import LoadingSpinner from "../ui/loadingSpinner";
import styles from "./styles/IdentityAssessment.module.css";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { LazyImage } from "../ui/lazyImage";
import {getFileName} from "../../utils/helpers/getFileName"

export function IdentityAssessmentResults() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.history.pushState("", "", `/#result-page`);
    if (window.location.ancestorOrigins[0]) {
      ReactGA.event({
        category: "identityAssessment/results-page",
        action: `${window.location.ancestorOrigins[0]}-${window.location.hash.replace("#", "")}`,
        label: "identityAssessment/results-page",
      });
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const identityAssessment: IdentityAssessmentResponse = useAppSelector(selectIdentityAssessment);
  const outcome: QuestionaryOutcome = ResultService.SelectResult(identityAssessment.data!);
  const overallScore = identityAssessment.data?.result.generalScore;
  const buttonLabel = identityAssessment.generatePdfStatus === "loading" ? "GENERATING PDF" : "DOWNLOAD";
  const buttonText =
    identityAssessment.generatePdfStatus === "loading"
      ? "Please wait while your PDF is being generated"
      : "Your PDF is ready to download";

  const optimizedWordVersion = getFileName().fileName === "DIMA_US.json" ? "Optimized" : "Optimised"
  
  if (loading) return <LoadingSpinner />;

  return (
    <div className={styles["result-container"]}>
      <div className={styles["result-content"]}>
        <div className={styles["result-box-text"]}>
          <div className={styles["results-page-header"]}>CONGRATULATIONS!</div>
          <div className={styles["results-page-header-text"]}>
            You’ve taken the first step toward maturing your digital identity strategy.
          </div>
          <b style={{ paddingTop: "2rem" }}>Your overall maturity level is:</b>
          <div className={styles["results-page-level-box"]}>
            {overallScore === 1 && (
              <div className={`${styles["overall-img"]} ${styles["flex-column-center"]}`}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/01_ranking_icon.png" />
                <p>PHASE 1</p>
                <p>
                  <b>Initial</b>
                </p>
              </div>
            )}
            {overallScore === 2 && (
              <div className={`${styles["overall-img"]} ${styles["flex-column-center"]}`}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/02_ranking_icon.png" />
                <p>PHASE 2</p>
                <p>
                  <b>Developing</b>
                </p>
              </div>
            )}
            {overallScore === 3 && (
              <div className={`${styles["overall-img"]} ${styles["flex-column-center"]}`}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/03_ranking_icon.png" />
                <p>PHASE 3</p>
                <p>
                  <b>Defined</b>
                </p>
              </div>
            )}
            {overallScore === 4 && (
              <div className={`${styles["overall-img"]} ${styles["flex-column-center"]}`}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/04_ranking_icon.png" />
                <p>PHASE 4</p>
                <p>
                  <b>Managed</b>
                </p>
              </div>
            )}
            {overallScore === 5 && (
              <div className={`${styles["overall-img"]} ${styles["flex-column-center"]}`}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/05_ranking_icon.png" />
                <p>PHASE 5</p>
                <p>
                  <b>{optimizedWordVersion}</b>
                </p>
              </div>
            )}
            <div
              className={styles["results-page-maturity-level-text"]}
              dangerouslySetInnerHTML={{ __html: outcome.text }}
            />
          </div>
        </div>
        <LazyImage src="small_graph.svg" />
      </div>
      <div>
        <div className={styles["download-button-text"]}>
          <img src="info.png" style={{paddingRight:'0.5rem'}}></img>
          {buttonText}
        </div>
        <DownloadButton label={buttonLabel} disabled={identityAssessment.generatePdfStatus === "loading"} />
      </div>
    </div>
  );
}
