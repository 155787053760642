import styles from "./styles/pdf.module.css";

export function PdfSecondPage() {
  const data = JSON.parse(localStorage.getItem("data")!);
  const pageNumber = data.pageNumber;
  const type = data.segment
  const content = data.pdfContent.second_page_content.find((content) => content.segment === type);
  const pdfSize = data.pdfSize

  return (
    <div className={`${styles["second-page"]} ${styles[pdfSize]}`}>
      <div className={styles["body-text"]}>
        <div className={styles["body-text-content"]}>
          <h1 className={styles["page-first-title"]}>IMPRIVATA DIGITAL IDENTITY MATURITY MODEL</h1>
          <div>
            <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/DIMA_Model.png" alt="" />
            <h2 className={styles["secondary-title"]}>{content.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: content.text }}></div>
          </div>
        </div>
        <div className={styles["page-number"]}>{pageNumber}</div>
      </div>
    </div>
  );
}
