
import { useAppSelector } from '../../app/redux/store';
import { IdentityAssessmentResponse, selectIdentityAssessment } from '../../app/identityAssessment/identityAssessment.slice';
import { Logger } from '../../utils/common/logger';
import { PdfRepeatingPage } from '../../components/pdf/pdf.repeatingPage';
import EnvironmentConfig from '../../utils/environmentConfig';
import '../../assets/styles/App.css';

export function IdentityAssessmentRepeatingPage(props) {
  const identityAssessment: IdentityAssessmentResponse = useAppSelector(selectIdentityAssessment);
 
  if (identityAssessment.status == "idle" || identityAssessment.status == "loading") {
    return null
  }

  {EnvironmentConfig.isDevelopment && Logger.log(identityAssessment.data)}

  return (
    <PdfRepeatingPage category={props.category}/>
  );
}
