import {DOMAIN_TO_FILE_BINDING} from "../domainToFile.js"

export function getFileName():any {
  const searchParams = new URLSearchParams(window.location.search);
  let urlString = 'https://www.imprivata.com/';

  if (searchParams.get('language') !== null) {
    urlString = urlString + searchParams.get('language');
  }

  return DOMAIN_TO_FILE_BINDING.find(obj => obj.address === urlString);
}
