import styles from "./styles/pdf.module.css";

export function PdfThirdPage() {
  const data = JSON.parse(localStorage.getItem("data")!);
  const overallScore = data.generalScore;
  const pageNumber = data.pageNumber;
  const type = data.segment
  const content = data.pdfContent.third_page_content.find(
    (content) => content.segment === type && content.needed_score === overallScore
  );
  const pdfSize = data.pdfSize
  const optimizedWordVersion = data.assessmentFileName === "DIMA_US.json" ? "Optimized" : "Optimised"

  return (
    <div className={`${styles["third-page"]} ${styles[pdfSize]}`}>
      <div className={styles["body-text-3"]}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={styles["page-title-description-3"]}>
              <h1 className={styles["page-first-title"]}>CONGRATULATIONS!</h1>

              <p>You've taken the first step toward maturing your digital identity strategy.</p>
            </div>
          </div>

          <div className={styles["page-2-custom-text"]}>
            <h2>{content.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: content.text }}></div>
          </div>
          <div className={styles["overall-section"]}>
            <h2>
              <b>Your overall maturity level is:</b>
            </h2>
            {overallScore === 1 && (
              <div className={styles["overall-img"]}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/01_ranking_icon.png" />
                <p>PHASE 1</p>
                <p>
                  <b>Initial</b>
                </p>
              </div>
            )}
            {overallScore === 2 && (
              <div className={styles["overall-img"]}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/02_ranking_icon.png" />
                <p>PHASE 2</p>
                <p>
                  <b>Developing</b>
                </p>
              </div>
            )}
            {overallScore === 3 && (
              <div className={styles["overall-img"]}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/03_ranking_icon.png" />
                <p>PHASE 3</p>
                <p>
                  <b>Defined</b>
                </p>
              </div>
            )}
            {overallScore === 4 && (
              <div className={styles["overall-img"]}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/04_ranking_icon.png" />
                <p>PHASE 4</p>
                <p>
                  <b>Managed</b>
                </p>
              </div>
            )}
            {overallScore === 5 && (
              <div className={styles["overall-img"]}>
                <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/05_ranking_icon.png" />
                <p>PHASE 5</p>
                <p>
                  <b>{optimizedWordVersion}</b>
                </p>
              </div>
            )}
          </div>
          <div className={styles["results-section"]}>
            {data.categoryResults.map((category) => {
              const categoryScore = category.score;
              if(category.name.toLowerCase() === 'authorization' && data.assessmentFileName === "DIMA_INTL.json") category.name = "Authorisation"

              return (
                <div className={styles["gov-admin-result"]}>
                  {categoryScore === 1 && (
                    <div className={styles["results-img"]}>
                      <p>
                        <b>{category.name}</b>
                      </p>
                      <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/01_ranking_icon.png" />
                      <p>PHASE 1</p>
                      <p>
                        <b>Initial</b>
                      </p>
                    </div>
                  )}
                  {categoryScore === 2 && (
                    <div className={styles["results-img"]}>
                      <p>
                        <b>{category.name}</b>
                      </p>
                      <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/02_ranking_icon.png" />
                      <p>PHASE 2</p>
                      <p>
                        <b>Developing</b>
                      </p>
                    </div>
                  )}
                  {categoryScore === 3 && (
                    <div className={styles["results-img"]}>
                      <p>
                        <b>{category.name}</b>
                      </p>
                      <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/03_ranking_icon.png" />
                      <p>PHASE 3</p>
                      <p>
                        <b>Defined</b>
                      </p>
                    </div>
                  )}
                  {categoryScore === 4 && (
                    <div className={styles["results-img"]}>
                      <p>
                        <b>{category.name}</b>
                      </p>
                      <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/04_ranking_icon.png" />
                      <p>PHASE 4</p>
                      <p>
                        <b>Managed</b>
                      </p>
                    </div>
                  )}
                  {categoryScore === 5 && (
                    <div className={styles["results-img"]}>
                      <p>
                        <b>{category.name}</b>
                      </p>
                      <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/05_ranking_icon.png" />
                      <p>PHASE 5</p>
                      <p>
                        <b>{optimizedWordVersion}</b>
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles["page-number"]}>{pageNumber}</div>
      </div>
    </div>
  );
}
