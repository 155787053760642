import "./dropdownList.css";
import { useState, useEffect } from "react";

export default function DropdownList(props) {
  const [showMenu, setShowMenu] = useState(props.showDropdown);
  const [selectedValue, setSelectedValue] = useState({ text: "" });

  const show = props.showDropdown
  useEffect(() => {
    setShowMenu(props.showDropdown)
  }, [show]);
  
  useEffect(() => {
    setShowMenu(showMenu)
    props.setShowIndustryDropdown(showMenu)
  }, [showMenu]);

  const handleInputClick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleOnBlur = () => {
    setShowMenu(false)
  };

  const getDisplay = () => {
    if (selectedValue.text) {
      return selectedValue?.text;
    }
    return props.isMandatory ? <div>{props.placeholder}<span style={{color: '#cc0033'}}>*</span></div> : props.placeholder
  };

  const onItemClick = (option) => {
    setSelectedValue(option);
    props.selectItem(option);
  };

  const isSelected = (option) => {
    if (!selectedValue.text) {
      return false;
    }
    return selectedValue.text === option.text;
  };

  const dropdownItemClassess = () => {
    return !selectedValue.text ? "initial-value" : "";
  };

  return (
    <div className={`dropdown-container ${props.error ? 'invalid': ''} ${showMenu ? 'dropdown-container-open' : ''}`}>
      <div className="dropdown-input" onClick={handleInputClick} onBlur={handleOnBlur}>
        <div className={`dropdown-selected-value ${dropdownItemClassess()}`}>{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon open={showMenu} />
          </div>
        </div>
      </div>
        <div className={`dropdown-menu ${showMenu ? '' : 'hidden'}`}>
          {props.options.map((option) => (
            <div
              key={option.text}
              onClick={() => onItemClick(option)}
              className={`dropdown-item ${isSelected(option) && "selected"}`}
            >
              {option.text}
            </div>
          ))}
        </div>
    </div>
  );
}

const Icon = (props) => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20" style={props.open ? {'transform':'rotate(180deg)'} : {}}>
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};
