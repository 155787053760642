import styles from "./styles/pdf.module.css";

export function PdfFinalPage() {
  const data = JSON.parse(localStorage.getItem("data")!);
  const pageNumber = data.pageNumber;
  const type = data.segment
  const content = data.pdfContent.last_page_content.find((content) => content.segment === type);
  const pdfSize = data.pdfSize

  return (
    <div className={`${styles["third-page"]} ${styles[pdfSize]}`}>
      <div className={styles["final-body-text"]}>
        <div>
          <div className={styles["final-page-custom-text"]}>
            <h2>{content.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: content.text }}></div>
          </div>
        </div>
        <div className={styles["page-number"]}>{pageNumber}</div>
      </div>
    </div>
  );
}