import {useState, useEffect} from 'react'
import LoadingSpinner from './loadingSpinner';

export function LazyImage(props) {
  const [source, setSource] = useState("");
  let src = props.src
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSource(src);
    img.onerror = () => setSource(src);
  }, [src]);

  if (!source) return <LoadingSpinner />;
  if(props.isBackgroundIage) return <div className="app-background-image" style={{ backgroundImage: `url(${source})` }}></div>;
  else return <img  src={source} />
}