import { IdentityAssessmentResponse, selectIdentityAssessment } from "../../app/identityAssessment/identityAssessment.slice";
import { useAppSelector } from "../../app/redux/store";
import { ErrorTypes, Logger } from "../../utils/common/logger";
import { useState } from 'react';
import styles from "../identityAssessment/styles/IdentityAssessment.module.css";
import ReactGA from "react-ga4";

export function DownloadButton(props) {
  const identityAssessment: IdentityAssessmentResponse = useAppSelector(selectIdentityAssessment);
  const [isLoading, setIsLoading] = useState(false);

  const data = identityAssessment?.data?.result
  async function handleClick(e: any) {
    e.preventDefault();
    setIsLoading(true)
    if(window.location.ancestorOrigins[0]) {
    ReactGA.event({
      category: 'identityAssessment/downloadPDF',
      action:`${window.location.ancestorOrigins[0]}-download-${identityAssessment.pdfName}`,
      label:'identityAssessment/downloadPDF'
    })
  }

    try {

      if (identityAssessment.pdf) {
        document.getElementById('download-link')?.click()
      }

    } catch (error) {
      Logger.error(error, ErrorTypes['generate-pdf-failed']);
      setIsLoading(false)
    }
  }

  return (
      <div className={styles["download-button"]}>
        <button className={styles.button} onClick={handleClick} disabled={props.disabled}>
          <div style={{paddingTop:'0.5rem'}}>{props.label}</div>
          {props.disabled ? <img style={{width:'2rem', height:'2rem', marginBottom:'none'}} className="loading-spinner" src="dima_loading.gif" alt="Loading"/> : ''}
        </button>
      </div>
  );
}
