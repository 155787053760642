import {
  IdentityAssessment,
  QuestionaryOutcome,
  QuestionCategory,
  QuestionyResult,
  ScoreResults,
} from "../types/identityAssessment.types";
import { Logger } from "../../../utils/common/logger";
import { ErrorTypes } from "../../../utils/common/logger";

export interface QuestionCategoryResult extends QuestionCategory {
  score: number;
}

export class ResultService {
  static SelectResult(identityAssessment: IdentityAssessment): QuestionaryOutcome {
    const outcome = identityAssessment?.outcomes.find(
      (outcome) => (outcome.neededScore === identityAssessment.result.generalScore && outcome.questionId === 0)
    );
    return outcome || { id: 0, questionId: 0, title: "", text: "", neededScore: 0 };
  }

  static CalculateCategoryScore(categoryQuestions): Array<QuestionCategory> {
    const result = new Array<QuestionCategory>();
    Object.keys(categoryQuestions).map((category) => {
      let sumOfScores = 0;
      categoryQuestions[category].map((question) => (sumOfScores += question.answer.score));
      const filteredQuestions = categoryQuestions[category].filter(q => q.answer.score !== 0).length
      result.push({
        name: category,
        score: Math.floor(sumOfScores / filteredQuestions) || 0,
        questions: categoryQuestions[category],
      });
    });
    return result;
  }

  static CalculateScore(identityAssessment: IdentityAssessment): ScoreResults {
    let score: number = 0;
    let sumOfScores: number = 0;
    let numberOfQuestions: number = 0;
    let results: Array<QuestionyResult> = identityAssessment.result.results;
    let categoryScores: Array<QuestionCategory> = [];
    let categoryResults = {};
    for (let i = 0; i < results.length; i++) {
      var questionResult = results[i];
      var selectedQuestion = identityAssessment.questionSteps.find((x) => x.id == questionResult.questonId);

      if (!selectedQuestion) {
        Logger.error(questionResult, ErrorTypes["question-not-found"]);
        continue;
      }
      //SKIP THE QUESTION IF IT IS GENERAL - NOT INCLUDED IN THE SCORING
      if (!selectedQuestion.isGeneral) {
        var answer = selectedQuestion?.answers.find((x) => x.id == questionResult.answareId);
        if (!answer) {
          Logger.error(questionResult, ErrorTypes["answer-not-found"]);
          continue;
        }

        if (!categoryResults.hasOwnProperty(selectedQuestion.category)) categoryResults[selectedQuestion.category] = [];
        categoryResults[selectedQuestion.category].push({ ...selectedQuestion, answer: answer });

        if(answer.score !==0) numberOfQuestions++;
        sumOfScores += answer.score;
      }
    }
    categoryScores = this.CalculateCategoryScore(categoryResults);
    let sumOfCategoryScores = 0;
    categoryScores.map(category => sumOfCategoryScores+=category.score)
    score = Math.floor(sumOfCategoryScores / categoryScores.length) || 0;
    // score = Math.floor(sumOfScores / numberOfQuestions) || 0; // the other way of calculating the overall score

    return {
      generalScore: score,
      categoriesScore: categoryScores,
    };
  }
}
