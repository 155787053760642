import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import EnvironmentConfig from "../../../utils/environmentConfig";
import { DefaultMappingService } from ".././services/identityAssessment.defaultMapping.service";
import { ErrorTypes, Logger } from "../../../utils/common/logger";
import { IdentityAssessment } from "../types/identityAssessment.types";
import { PdfGenerationService } from "../services/identityAssessment.pdf.service";
import { getFileName } from "../../../utils/helpers/getFileName";

export interface PdfData {
  data: IdentityAssessment,
  segment: string
}

export const getContent = createAsyncThunk("identityAssesment/getContent", async () => {
  try {
    const assessmentFile = getFileName()?.file || "usAssessment"
    const response = await axios.get(EnvironmentConfig[assessmentFile]);
    return DefaultMappingService.Map(response.data);
  } catch (error) {
    Logger.error(error, ErrorTypes["identity-assessment-get-content-failed"]);
  }
});

export const generatePdf = createAsyncThunk("identityAssessment/generatePdf", async (data:PdfData) => {
  try {
    const assessmentFile = getFileName()?.fileName || 'DIMA_US.json'
    let filteredData= {result: {...data.data.result},categories:[...data.data.categories], assessmentFileName:assessmentFile, segment: data.segment, assessmentUrl: window.location.ancestorOrigins.length ? window.location.ancestorOrigins[0] : window.location.origin } //customerData , results
    const pdfGenerationUrl = PdfGenerationService.GetGenerationPdfUrl(data);
    const response:any = await axios.post(pdfGenerationUrl, filteredData, {
      responseType: 'arraybuffer'
    });
    const fileName = response.headers.get('Content-disposition').split('filename=')[1]
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.setAttribute('id','download-link');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    return {link:link.href, fileName:fileName};
  } catch (e) {
    console.log('Generation failed');
  }
});
