import { useEffect, useState, useRef } from "react";
import styles from "../../components/identityAssessment/styles/IdentityAssessment.module.css";

export function TextInputField(props) {
  const value = props.value;
  const [currentValue, setCurrentValue] = useState<string>(value);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <input
      ref={ref}
      placeholder={props.isMandatory ? "" : props.placeholder}
      required={props.isMandatory}
      className={`${styles.textbox} ${props.error.length ? styles.invalid : ""} ${
        props.isMandatory ? "mandatory" : ""
      }`}
      value={currentValue}
      name={props.name}
      onChange={(e) => {
        setCurrentValue(e.target.value);

        props.onChange(e);
      }}
      onBlur={props.onBlur}
    />
  );
}
