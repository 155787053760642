import {
  IdentityAssessment,
  IdentityAssessmentDesign,
  IdentityAssessmentResult,
  IdentityAssessmentSettings,
  IdentityAssessmentPdfContent,
  MarketoIntegration,
  StartStep,
  GAIntegration,
  DictionaryItem,
  IdentityAssessmentCustomerData,
  IdentityAssessmentError,
  QuestionStep,
  QuestionCategory,
  QuestionAnsware,
  QuestionaryOutcome,
  PdfFirstPage,
  PdfLastPage,
  PdfPage,
  PdfSettings,
  CustomField,
  PdfPageContent,
  GeneralQuestionResult,
} from "../types/identityAssessment.types";
import { Logger } from "../../../utils/common/logger";

const DOMAIN_TO_FILE_BINDING = [
  {address:"http://localhost:3000", trackingId: 'G-YVBVS78LS2' },
  {address:"https://www.imprivata.com",  trackingId: 'G-YVBVS78LS2' },
  {address:"https://intl.imprivata.com",  trackingId: 'G-YVBVS78LS2' },
  {address:"https://www.imprivata.co.uk",  trackingId: 'G-R1BK2E823T' },
  {address:"https://development-q5nzhaa-i3ybz43xhcoa2.us-2.platformsh.site",  trackingId: 'G-YVBVS78LS2' },
  ]

export class DefaultMappingService {
  static Map(apiResponse: any): IdentityAssessment {
    Logger.log(apiResponse);

    const identityAssessment: IdentityAssessment = this.CreateIdenityAssessment();

    identityAssessment.id = apiResponse.id;
    identityAssessment.name = apiResponse?.translations.title;
    identityAssessment.language = apiResponse?.translations.language_code;

    this.ProcessDictionaries(identityAssessment, apiResponse);
    this.ProcessDesign(identityAssessment, apiResponse);
    this.ProcessGaIntegration(identityAssessment, apiResponse);
    this.ProcessMarketoIntegration(identityAssessment, apiResponse);
    this.ProcessQuestionSteps(identityAssessment, apiResponse);
    this.ProcessOutcomes(identityAssessment, apiResponse);
    this.ProcessPdfPage(identityAssessment, apiResponse);
    this.ProcessPdfContent(identityAssessment, apiResponse);

    return identityAssessment;
  }

  static ProcessCustomFields(identityAssessment: IdentityAssessment, apiResponse: any) {
    identityAssessment.customFields = new Array<CustomField>();

    apiResponse.customfields
      .sort((a, b) => a.order - b.order)
      .map((field) => {
        if (!field.hide) {
          identityAssessment.customFields.push({
            id: field.id,
            mandatory: field.mandatory || field.hide,
            hide: field.hide,
            type: field.type.controlType,
            dropListValues: field.type.values || [],
            order: field.order,
            fieldName: field.field_name,
            label: field.label,
          });
        }
      });
    return identityAssessment.customFields;
  }
  static ProcessPdfPage(identityAssessment: IdentityAssessment, apiResponse: any) {
    const pdfFirstPage: PdfFirstPage = {
      title: "Digital Identity Maturity Assessment",
      image: "/html/assets/background.webp",
      prepeardForLabel: "Prepeard for:",
    };

    const pdfLastPage: PdfLastPage = {
      title: "What is next?",
      content: "",
      image: "",
    };

    const PdfSettings: PdfSettings = {
      backgroundUrl: "",
      backgroundLogo: "",
    };

    const pdfPage: PdfPage = {
      firstPage: pdfFirstPage,
      lastPage: pdfLastPage,
      pages: [],
      settings: PdfSettings,
    };

    identityAssessment.pdfPage = pdfPage;
  }

  static ProcessPdfContent(identityAssessment: IdentityAssessment, apiResponse: any) {
    const pdfPageContent: PdfPageContent = {
      segment: "",
      text: "",
    };

    const pdfPagesContent: IdentityAssessmentPdfContent = {
      secondPage: pdfPageContent,
      thirdPage: [],
      lastPage: pdfPageContent,
    };
    identityAssessment.pdfContent = pdfPagesContent;

    const apiResponsePdfContent = apiResponse.pdf_content;

    identityAssessment.pdfContent.secondPage = apiResponse.pdf_content.second_page_content;
    identityAssessment.pdfContent.thirdPage = apiResponse.pdf_content.third_page_content;
    identityAssessment.pdfContent.lastPage = apiResponse.pdf_content.last_page_content;
  }

  static ProcessOutcomes(identityAssessment: IdentityAssessment, apiResponse: any) {
    identityAssessment.outcomes = new Array<QuestionaryOutcome>();

    const apiResponseOutcomes = apiResponse.pdf_content.outcomes;

    for (let i = 0; i < apiResponseOutcomes.length; i++) {
      const apiResponseOutcome = apiResponseOutcomes[i];

      const questionOutcome: QuestionaryOutcome = {
        id: apiResponseOutcome.id,
        questionId: apiResponseOutcome.questionId,
        title: apiResponseOutcome.title,
        text: apiResponseOutcome.text.replaceAll("category-row", "category-row hide"), // hide displaying of chart
        neededScore: apiResponseOutcome.needed_score,
      };

      identityAssessment.outcomes.push(questionOutcome);
    }
  }
  static ProcessQuestionSteps(identityAssessment: IdentityAssessment, apiResponse: any) {
    identityAssessment.questionSteps = new Array<QuestionStep>();
    let apiResponseQuestions: any = [];

    let generalQuestions = [];
    let categories: string[] = [];
    //Add isGeneral and segment properties to all questions
    apiResponse.questionblocks.forEach((segment) => {
      //Segment questions filtered by locked property and  sorted by order
      let segmentQuestions = segment.questions
        .filter((questions) => !questions.locked)
        .sort((a, b) => a.order - b.order);
      if (segment.isGeneral) generalQuestions = segmentQuestions;
      segmentQuestions.map((q, index) => {
        if (!categories!.includes(q.category)) categories.push(q.category);
        q.isGeneral = segment.isGeneral;
        q.segment = segment.segment;
        if (segment.isGeneral && index === 0) q.order = index + 1;
        if (!segment.isGeneral) q.order = index + generalQuestions.length;
      });
      apiResponseQuestions.push(...segmentQuestions);
    });

    for (let i = 0; i < apiResponseQuestions.length; i++) {
      const apiResponseQuestion = apiResponseQuestions[i];
      const apiResponseQuestionAnswers = apiResponseQuestion.answers;
      const questionAsnwares = new Array<QuestionAnsware>();

      for (let j = 0; j < apiResponseQuestionAnswers.length; j++) {
        const questionAsnware: QuestionAnsware = {
          id: apiResponseQuestionAnswers[j].id,
          text: apiResponseQuestionAnswers[j].text,
          textInfoTranslationKey: `${apiResponseQuestionAnswers[j].text}-answare-label`,
          type: apiResponseQuestionAnswers[j].type.controlType,
          segmentSelector: apiResponseQuestionAnswers[j].segment_selector || "",
          order: apiResponseQuestionAnswers[j].order,
          score: apiResponseQuestionAnswers[j].score,
          questionId: apiResponseQuestion.id,
        };
        questionAsnwares.push(questionAsnware);
      }

      let questionStep: QuestionStep = {
        id: apiResponseQuestion.id,
        order: apiResponseQuestion.order,
        // mandatory: true,
        category: apiResponseQuestion.category,
        categoryLabel: apiResponseQuestion.category_displayed_title,
        identifier: apiResponseQuestion.question_identifier,
        locked: apiResponseQuestion.locked,
        // scoreWeight: apiResponseQuestion.score_weight,
        text: apiResponseQuestion.question_text,
        isGeneral: apiResponseQuestion.isGeneral,
        segment: apiResponseQuestion.segment,
        answers: questionAsnwares.sort((a, b) => a.order - b.order), // sort answers by order
        imageAlignment: apiResponseQuestion.question_image_alignment || "",
        imageUrl: apiResponseQuestion.question_image_url || "",
      };

      identityAssessment.questionSteps.push(questionStep);
      identityAssessment.categories = categories.filter((c) => c !== "Segmentation");
    }
  }
  static ProcessMarketoIntegration(identityAssessment: IdentityAssessment, apiResponse: any) {
    Logger.log("Method not implemented.");
  }
  static ProcessGaIntegration(identityAssessment: IdentityAssessment, apiResponse: any) {
    identityAssessment.ga.trackingId = apiResponse?.settings.googleanalytics.value;
    if(window.location.ancestorOrigins.length) {
      origin = window.location.ancestorOrigins[0]
      identityAssessment.ga.trackingId = DOMAIN_TO_FILE_BINDING.find(obj => obj.address.includes(origin))?.trackingId || apiResponse?.settings.googleanalytics.value
    }
  }

  static ProcessDesign(identityAssessment: IdentityAssessment, apiResponse: any) {
    identityAssessment.design.background = apiResponse?.design.background;
    identityAssessment.design.backgroundMobile = apiResponse?.design.background_mobile;
  }

  static ProcessDictionaries(identityAssessment: IdentityAssessment, apiResponse: any) {
    identityAssessment.dictionaries = new Array<DictionaryItem>();
    let labels = apiResponse?.translations.labels;

    const keys = Object.keys(labels);
    const values = keys.map((key) => `${Reflect.get(labels, key)}`);

    for (let i = 0; i < keys.length; i++) {
      let dictionaryItem: DictionaryItem = {
        key: keys[i],
        phrase: values[i],
        rawHtml: "",
      };
      identityAssessment.dictionaries.push(dictionaryItem);
    }

    identityAssessment.dictionaries.push({
      key: "intro",
      phrase: "",
      rawHtml: apiResponse?.translations.intro.text,
    });
    const customFields = this.ProcessCustomFields(identityAssessment, apiResponse);

    customFields.map((field) => {
      identityAssessment.dictionaries.push({
        key: field.fieldName,
        phrase: field.label,
        rawHtml: "",
      });
    });

    this.ProcessFirstSteps(identityAssessment, apiResponse, customFields);
  }

  static ProcessFirstSteps(identityAssessment: IdentityAssessment, apiResponse: any, customFields: Array<CustomField>) {
    identityAssessment.startStep = {
      text: apiResponse.translations.intro.text,
      firstNameMandatory: customFields.find((field) => field.fieldName === "firstName")?.mandatory || false,
      lastNameMandatory: customFields.find((field) => field.fieldName === "lastName")?.mandatory || false,
      organizationMandatory: customFields.find((field) => field.fieldName === "organization")?.mandatory || false,
      emailMandatory: customFields.find((field) => field.fieldName === "email")?.mandatory || false,
      termsAndConditionMandatory: customFields.find((field) => field.fieldName === "consent")?.mandatory || false,
      industryMandatory: customFields.find((field) => field.fieldName === "industry")?.mandatory || false,
    };
  }
  static CreateIdenityAssessment(): IdentityAssessment {
    const identityAssessmentDesign: IdentityAssessmentDesign = {
      logo: "",
      background: "",
      backgroundMobile: "",
    };

    const identityAssessmentSettings: IdentityAssessmentSettings = {};

    const marketoIntegration: MarketoIntegration = {
      munchkinId: "",
    };

    const gAIntegration: GAIntegration = {
      trackingId: "",
    };

    const startStep: StartStep = {
      text: "",
      firstNameMandatory: false,
      lastNameMandatory: false,
      organizationMandatory: false,
      emailMandatory: false,
      termsAndConditionMandatory: false,
      industryMandatory: false,
    };

    const customerData: IdentityAssessmentCustomerData = {
      firstName: "",
      lastName: "",
      email: "",
      organization: "",
      industry: "",
      termsAndPrivacy: false,
    };
    const generalQuestionResult: GeneralQuestionResult = {
      title: "",
      employees: 0,
    };

    const identityAssessmentResult: IdentityAssessmentResult = {
      results: [],
      categoryResults: [],
      customerData: customerData,
      generalScore: 0,
      generalQuestionsResults: generalQuestionResult,
    };

    const identityAssessmentError: IdentityAssessmentError = {
      firstPageError: false,
      questionError: true,
      genericError: false,
      textError: false,
    };

    const pdfFirstPage: PdfFirstPage = {
      title: "",
      image: "",
      prepeardForLabel: "",
    };

    const pdfLastPage: PdfLastPage = {
      title: "",
      content: "",
      image: "",
    };

    const PdfSettings: PdfSettings = {
      backgroundUrl: "",
      backgroundLogo: "",
    };

    const pdfPage: PdfPage = {
      firstPage: pdfFirstPage,
      lastPage: pdfLastPage,
      pages: [],
      settings: PdfSettings,
    };

    const pdfPageContent: PdfPageContent = {
      segment: "",
      text: "",
    };

    const pdfPagesContent: IdentityAssessmentPdfContent = {
      secondPage: pdfPageContent,
      thirdPage: [],
      lastPage: pdfPageContent,
    };

    const identityAssessment: IdentityAssessment = {
      id: "",
      name: "",
      version: 0,
      language: "",
      design: identityAssessmentDesign,
      settings: identityAssessmentSettings,
      dictionaries: [],
      marketo: marketoIntegration,
      ga: gAIntegration,
      startStep: startStep,
      questionSteps: [],
      outcomes: [],
      customFields: [],
      pdfPage: pdfPage,
      result: identityAssessmentResult,
      error: identityAssessmentError,
      messages: [],
      categories: [],
      pdfContent: pdfPagesContent,
    };

    Logger.log(identityAssessment);

    return identityAssessment;
  }
}
