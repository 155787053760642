import { configureStore, ThunkAction, Action, ThunkDispatch, AnyAction, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import  identityAssessment, { saveAnswer, startAssessment, saveResults}  from '../identityAssessment/identityAssessment.slice';
import {addAppListener, startAppListening, listenerMiddleware} from './listenerMiddleWare'
import ReactGA from "react-ga4";

startAppListening({
  matcher: isAnyOf(saveAnswer, startAssessment, saveResults),
  effect: (action, listenerApi) => {
    if(window.location.ancestorOrigins[0]) {
      ReactGA.event({
          category: action.type,
          action:`${window.location.ancestorOrigins[0]}-${window.location.hash.replace('#','')}`,
          label:action.type
        })
    }
  }
})

export const store = configureStore({
  reducer: {
    identityAssessment
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().prepend(listenerMiddleware.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch  = (): ThunkDispatch<RootState, null, AnyAction> => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store