import { DictionaryItem } from "../../app/identityAssessment/types/identityAssessment.types";

export function GetLabel(dictionaries: Array<DictionaryItem>, key: string): string {
  var dictionaryItem = dictionaries.find(x => x.key.toLowerCase() == key.toLowerCase());

  if (!dictionaryItem) {
    return `Given dictionary key '${key}' does not exist`
  }

  return dictionaryItem.phrase;
}

export function Get(dictionaries: Array<DictionaryItem>, key: string): DictionaryItem {
  var dictionaryItem = dictionaries.find(x => x.key.toLowerCase() == key.toLowerCase());

  if (!dictionaryItem) {
    var defaultDictionaryItem: DictionaryItem = {
      key: "null",
      phrase: `Given dictionary key '${key}' does not exist`,
      rawHtml: `Given dictionary key '${key}' does not exist`
    }

    return defaultDictionaryItem;
  }

  return dictionaryItem;
}