import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/redux/store";
import {
  selectIdentityAssessment,
  saveCustomerData,
  startAssessment,
  saveSegmentSelector,
  saveError,
  clearResults,
} from "../../app/identityAssessment/identityAssessment.slice";
import { GetLabel } from "../../utils/helpers/labelHelper";
import { IdentityAssessmentCustomerData } from "../../app/identityAssessment/types/identityAssessment.types";
import { IdentityAssessmentResponse } from "../../app/identityAssessment/identityAssessment.slice";
import { ValidationService } from "../../app/identityAssessment/services/identityAssessment.validation.service";
import styles from "./styles/IdentityAssessment.module.css";
import { SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import DropdownList from "../ui/dropdownList";
import { TextInputField } from "../ui/textInput";

const StartButton = (props) => {
  const swiper = useSwiper();
  const disableButton = !ValidationService.IsValid(
    props.identityAssessment.data!,
    props.identityAssessment.data?.result.customerData!
  );
  return (
    <div className={styles["first-page-button"]}>
      <button
        disabled={disableButton}
        className={`${styles.button} ${styles["first-page-start-button"]}`}
        aria-label="Increment value"
        onClick={() => props.startAssessment(swiper)}
      >
        {GetLabel(props.identityAssessment.data?.dictionaries!, "start_survey").toUpperCase()}
      </button>
    </div>
  );
};

export function IdentityAssessmentFirstPage(props) {
  const identityAssessment: IdentityAssessmentResponse = useAppSelector(selectIdentityAssessment);
  const dispatch = useAppDispatch();
  const initialState: IdentityAssessmentCustomerData = {
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    industry: "",
    termsAndPrivacy: false,
  };

  const initialErrorState = {
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    termsAndPrivacy: "",
    isEmailFocused: "",
    industry: "",
  };

  const [customerData, setCustomerData] = useState(initialState);
  const [errors, setErrors] = useState(initialErrorState);
  const [showDropdown, setShowDropdown] = useState(false);

  const setErrorFields = (fieldName, error) => {
    if (identityAssessment.data?.customFields.find((field) => fieldName === fieldName)?.mandatory) {
      setErrors((prevState: any) => ({
        ...prevState,
        [fieldName]: error,
      }));
    }
  };
  useEffect(() => {
    window.history.pushState("", "", "/#first-page");
  }, []);

  useEffect(() => {
    dispatch(saveError({ name: "firstPageError", value: Object.values(errors).some((error) => !!error) }));
  }, [errors]);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setCustomerData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    let error = "";
    if (name !== "email" && value.length > 50) error = "length";
    if (name === "email" && value.length > 100) error = "length";
    setErrorFields(name, error);
  };

  const handleOnBlur = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    let error = "";
    if (!value) error = "required";
    if (name === "email" && value.length > 100) error = "length";
    else if (name === "email" && value) {
      const isValid = ValidationService.IsValidEmail(identityAssessment.data!.result.customerData.email) && !ValidationService.isEmailInBlockList(identityAssessment.data!.result.customerData.email);
      isValid ? (error = "") : (error = "invalid");
    }
    if (name !== "email" && value.length > 50) error = "length";
    setErrorFields(name, error);
  };

  const handleTermsAndConditionChange = (e: { target: { name: any; checked: boolean } }) => {
    const { name, checked } = e.target;
    setCustomerData((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
    let error = !checked ? "required" : "";
    setErrorFields("termsAndPrivacy", error);
  };

  const handleDropDownSelection = (option) => {
    setCustomerData((prevState: any) => ({
      ...prevState,
      industry: option.text,
    }));
    dispatch(saveSegmentSelector(option));
    dispatch(clearResults());
  };

  useEffect(() => {
    dispatch(saveCustomerData(customerData));
    if (customerData.industry) {
      setErrorFields("industry", "");
    }
  }, [customerData]);

  const onStartAssessment = (swiper) => {
    dispatch(startAssessment());
    props.startAssessment(swiper);
  };

  const onShowDropdownChange = (value) => {
    if (showDropdown) {
      let error = !customerData.industry ? "required" : "";
      setErrorFields("industry", error);
    }
    setShowDropdown(value);
  };

  const isFieldVisible = (field) => {
    return identityAssessment.data?.customFields.find((customField) => customField.fieldName === field);
  };

  const introText = identityAssessment.data?.startStep.text || "";

  const options = identityAssessment.data?.customFields.find((field) => field.fieldName === "industry")?.dropListValues;

  const onKeyUp = (event) => {};

  return (
    <SwiperSlide>
      <div className={styles["first-page-container"]} onClick={() => onShowDropdownChange(false)} onKeyUp={onKeyUp}>
        <div className={styles["first-page-content"]}>
          <div className={styles["component-description"]}>
            <div dangerouslySetInnerHTML={{ __html: introText }} />
          </div>
          <div className={styles["first-page-names"]}>
            {isFieldVisible("firstName") &&
              displayInput(identityAssessment, "firstName", handleChange, handleOnBlur, errors.firstName, customerData)}
            {isFieldVisible("lastName") &&
              displayInput(identityAssessment, "lastName", handleChange, handleOnBlur, errors.lastName, customerData)}
          </div>
          {isFieldVisible("organization") &&
            displayInput(
              identityAssessment,
              "organization",
              handleChange,
              handleOnBlur,
              errors.organization,
              customerData
            )}
          {isFieldVisible("industry") &&
            displayDropdown(
              identityAssessment,
              "industry",
              handleDropDownSelection,
              handleOnBlur,
              options,
              errors.industry,
              showDropdown,
              onShowDropdownChange
            )}
          {isFieldVisible("email") &&
            displayEmailInput(identityAssessment, handleChange, handleOnBlur, errors.email, customerData)}
          {isFieldVisible("consent") &&
            displayTermsAndPrivacyPolicyInput(
              identityAssessment,
              handleTermsAndConditionChange,
              errors.termsAndPrivacy
            )}
        </div>

        <div className={styles["first-page-button-row"]}>
          <StartButton
            errors={errors}
            identityAssessment={identityAssessment}
            startAssessment={(swiper) => onStartAssessment(swiper)}
          />
        </div>
      </div>
    </SwiperSlide>
  );
}

function displayEmailInput(
  identityAssessment: IdentityAssessmentResponse,
  handleChange: any,
  handleOnBlur: any,
  error: any,
  customerData: IdentityAssessmentCustomerData
) {
  const isMandatory = identityAssessment.data?.startStep.emailMandatory;
  const placeholder = GetLabel(identityAssessment.data?.dictionaries!, "email");
  let errorText = "";
  if (error === "required") errorText = "Required field";
  if (error === "length") errorText = "The characters limit for this field is 100";
  if (error === "invalid") errorText = "Please enter your corporate email";

  return (
    <div className={`${styles.row}`}>
      <div className={styles["row-content"]}>
        <TextInputField
          isMandatory={isMandatory}
          error={error}
          name="email"
          value={identityAssessment.data?.result.customerData.email}
          onChange={handleChange}
          onBlur={handleOnBlur}
        />
        {isMandatory && !customerData.email && (
          <label className={styles.placeholder} htmlFor="email">
            {placeholder}
          </label>
        )}
      </div>
      {error && <label className={styles["first-page-row-error"]}>{errorText}</label>}
    </div>
  );
}

function displayInput(
  identityAssessment: IdentityAssessmentResponse,
  prop: string,
  handleChange: any,
  handleOnBlur: any,
  error: any,
  customerData: IdentityAssessmentCustomerData
) {
  const isMandatory = identityAssessment.data?.startStep[`${prop}Mandatory`];
  const placeholder = GetLabel(identityAssessment.data?.dictionaries!, prop);
  return (
    <div className={`${styles.row} ${styles["input-placeholder"]}`}>
      <div className={styles["row-content"]}>
        <TextInputField
          isMandatory={isMandatory}
          error={error}
          name={prop}
          value={identityAssessment.data?.result.customerData[prop as keyof IdentityAssessmentCustomerData] as string}
          onChange={handleChange}
          onBlur={handleOnBlur}
        />
        {isMandatory && !customerData[prop] && (
          <label className={styles.placeholder} htmlFor={prop}>
            {placeholder}
          </label>
        )}
      </div>
      {error && (
        <div className={styles["first-page-row-error"]}>
          {error === "required" && <label>Required field</label>}
          {error === "length" && <label>The characters limit for this field is 50</label>}
        </div>
      )}
    </div>
  );
}

function displayDropdown(
  identityAssessment: IdentityAssessmentResponse,
  prop: string,
  handleChange: any,
  handleOnBlur: any,
  options: any,
  error: any,
  showDropdown: boolean,
  onShowDropdownChange: any
) {
  const isMandatory = identityAssessment.data!.startStep.industryMandatory;

  return (
    <div className={`${styles.row} ${styles["row-dropdown"]}`}>
      <DropdownList
        name="dropdown"
        error={error}
        setShowIndustryDropdown={onShowDropdownChange}
        placeholder="Select Industry"
        showDropdown={showDropdown}
        options={options}
        selectItem={handleChange}
        isMandatory={isMandatory} //this field should always mandatory cause we have to know which questions to display
      />
      {error && (
        <div className={styles["first-page-row-error"]}>
          <label>Required field</label>
        </div>
      )}
    </div>
  );
}

function displayTermsAndPrivacyPolicyInput(
  identityAssessment: IdentityAssessmentResponse,
  handleChange: any,
  error: any
) {
  const isMandatory = identityAssessment.data!.startStep.termsAndConditionMandatory;
  const placeholder = GetLabel(identityAssessment.data?.dictionaries!, "consent");

  return (
    <div className={styles["privacy-terms-container"]}>
      <div className={styles["row-terms"]}>
        <p>Imprivata is committed to protecting your privacy, and we’ll only use your personal information to share with
        you relevant information about our services. By submitting, you agree to receive communications in accordance
        with our
        <a href="https://www.imprivata.com/privacy-policy" target="_blank">
          privacy policy
        </a>
        , which you may change or unsubscribe from at any time.</p>
      </div>
    </div>
  );
}
