import { IdentityAssessment } from "../types/identityAssessment.types";
import EnvironmentConfig from "../../../utils/environmentConfig";

export interface PdfData {
  data: IdentityAssessment,
  segment: string
} 

export class PdfGenerationService {
    static GetGenerationPdfUrl(identityAssessment: PdfData) : string {
        let queryString: string = `name=${identityAssessment.data.result.customerData.firstName}` +
          `&lastName=${identityAssessment.data.result.customerData.lastName}` +
          `&company=${identityAssessment.data.result.customerData.organization}`;
        return `${EnvironmentConfig.pdfGenerateUrl}${queryString}`;
        // return `${EnvironmentConfig.pdfGenerateUrl}${queryString}`;
    }
}