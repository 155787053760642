import { useAppSelector, useAppDispatch } from "../app/redux/store";
import {
  IdentityAssessmentResponse,
  selectIdentityAssessment,
  startAssessment,
  prevStep,
} from "../app/identityAssessment/identityAssessment.slice";
import { IdentityAssessmentFirstPage } from "../components/identityAssessment/IdentityAssessment.firstPage";
import { IdentityAssessmentQuestions } from "../components/identityAssessment/IdentityAssessment.questions";
import { IdentityAssessmentResults } from "../components/identityAssessment/IdentityAssessment.results";
import EnvironmentConfig from "../utils/environmentConfig";
import { Logger } from "../utils/common/logger";
import styles from "../components/identityAssessment/styles/IdentityAssessment.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { useState, useEffect, useRef } from "react";
import { ValidationService } from "../app/identityAssessment/services/identityAssessment.validation.service";
import { generatePdf } from "../app/identityAssessment/thunks/getContent.thunks";

export function IdentityAssessmentForm() {
  const [swiperInstance, setSwiperInstance] = useState({ slidePrev: () => {} });
  const [isResultPage, setIsResultPage] = useState(false);
  const [componentContainerClass, setComponentContainerClass] = useState("container");
  const [questions, setQuestions] = useState([]);
  const [questionContainerHeight, setQuestionContainerHeight] = useState(0);
  const dispatch = useAppDispatch();
  const container = useRef<HTMLInputElement>(null)

  const identityAssessment: IdentityAssessmentResponse = useAppSelector(selectIdentityAssessment);
  const currentStep = identityAssessment.currentStep;
  const finalResult = identityAssessment.data?.result.generalScore;

  useEffect(() => {
    if (isResultPage) {
      dispatch(generatePdf({data: identityAssessment.data!, segment: identityAssessment.segment}))
    };
  }, [finalResult, isResultPage]);
  if (identityAssessment.status == "idle" || identityAssessment.status == "loading") {
    return null
  }
  {
    EnvironmentConfig.isDevelopment && Logger.log(identityAssessment.data);
  }

  const beforeMovement = (swiper) => {
    const validCustomerData = ValidationService.IsValid(
      identityAssessment.data!,
      identityAssessment.data?.result.customerData!
    );
    if (!validCustomerData) swiper.slidePrev();
  };

  const changedSlide = (swiper) => {
    setSwiperInstance(swiper);
    if (currentStep === questions.length) swiper.allowSlideNext = false;
    if (swiper.realIndex === 0 && swiper.swipeDirection && swiper.swipeDirection === "prev") dispatch(prevStep());
    if (
      (swiper.realIndex === 1 && swiper.swipeDirection === "next") ||
      (swiper.realIndex === 0 && swiper.swipeDirection === "next")
    )
      dispatch(startAssessment());
  };

  const onKeyDown = (event) => {
      if (event.target.name === 'email' && event.key === "Tab" || event.target.type !== 'text' && event.key === "Tab") {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onKeyUp = (event) => {
  }

  const onStart = (swiper) => {
    if (ValidationService.IsValid(identityAssessment.data!, identityAssessment.data?.result.customerData!))
      swiper.slideNext();
  };

  const onReturnUserSlide = () => {
    swiperInstance.slidePrev();
  };

  const onFinalQuestion = () => {
    setIsResultPage(true);
  };

  const onResize = (isBigger) => {
    isBigger ? setComponentContainerClass(`container-with-picture`) : setComponentContainerClass(`container`);
  };

  const onSetQuestionBlockHeight = (height) => {
    setQuestionContainerHeight(height);
  };

  const onSegmentSelect = (questionList) => {
    setQuestions(questionList);
  };

  const progressBar = questions.map((question, index) => {
    let activeClass = "";
    if (index < currentStep) {
      activeClass = "active-dash";
    }
    return <div className={`${styles["progress-bar-dash"]} ${styles[activeClass]}`} key={index}></div>;
  });

  const firstPageHeight = document.querySelector("#first-page")?.getBoundingClientRect().height || "auto";
  let containerHeight = currentStep === 0 ? firstPageHeight : "100%";

  const getClasses = () => {
    if (!!questionContainerHeight && !isNaN(questionContainerHeight) && document.querySelector(".swiper")) {
      if (questionContainerHeight < document.querySelector(".swiper")!.clientHeight) {
        return `${styles["swiper-container"]} ${styles["swiper-container-full-content-visible"]}`;
      }
    }

    if (currentStep === 0 && document.querySelector<HTMLElement>("#first-page"))
      document.querySelector<HTMLElement>("#first-page")!.style.overflow = "unset";
    if (!questionContainerHeight && currentStep === 0) containerHeight = firstPageHeight;
    else if (questionContainerHeight && currentStep > 0) {
      document.querySelector<HTMLElement>("#first-page")!.style.overflow = "hidden";
      containerHeight = questionContainerHeight;
    } else containerHeight = "100%";

    return `${styles["swiper-container"]}`;
  };

  return (
    <div className={`${styles.container} ${styles[componentContainerClass]}`} id={"container"} onKeyUp={onKeyUp} onKeyDown={onKeyDown} ref={container}>
      <div className={styles["header-container"]} id={"header"}>
        <h1 className={styles.header}>{identityAssessment.data?.name}</h1>

        {currentStep > 0 && !isResultPage && identityAssessment.segment !=='health' && <div className={styles["progress-bar-container"]}>{progressBar}</div>}
      </div>

      {!isResultPage && (
        <Swiper
          id="overall-swiper"
          className={getClasses()}
          style={{ height: containerHeight }}
          spaceBetween={50}
          slidesPerView={1}
          threshold={20}
          onSlideNextTransitionStart={beforeMovement}
          onSetTransition={changedSlide}
          loopFillGroupWithBlank={false}
          noSwipingSelector="input"
        >
          <SwiperSlide id="first-page">
            <IdentityAssessmentFirstPage startAssessment={onStart} />
          </SwiperSlide>
          <SwiperSlide id="question-page">
            <IdentityAssessmentQuestions
              initialHeight={containerHeight}
              showUserSlide={onReturnUserSlide}
              finalQuestion={onFinalQuestion}
              resizeComponent={onResize}
              setQuestionBlockHeight={onSetQuestionBlockHeight}
              setSegment={onSegmentSelect}
            />
          </SwiperSlide>
        </Swiper>
      )}
      {isResultPage && <IdentityAssessmentResults />}
    </div>
  );
}
