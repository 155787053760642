import styles from "./styles/pdf.module.css";

export function PdfRepeatingPage(props) {
  const data = JSON.parse(localStorage.getItem("data")!);
  const title = props.category;
  let displayTitle = title
  if(title.toLowerCase() === 'authorization' && data.assessmentFileName === "DIMA_INTL.json") displayTitle = "Authorisation"
  const categoryResults = data.categoryResults.find((categoryResults) => categoryResults.name === title);
  const categoryScore = categoryResults.score;
  const questionIndex = parseInt(data.questionIndex);
  const question = categoryResults.questions.find((q, index) => index === questionIndex);
  const outcomes = data.outcomes;
  const pageNumber = data.pageNumber;
  const pdfSize = data.pdfSize

  const getOutcomeText = (questionId, answerScore) => {
    return outcomes.find((o) => o.questionId === questionId && o. needed_score === answerScore);
  };
  const overAllScoreOutcome = outcomes.find((o) => o.questionId === 0 && o. needed_score === categoryScore);

  return (
    <div className={`${styles["repeating-page"]} ${styles[pdfSize]}`}>
      <div className={styles["pagebreak"]}>
        <div className={styles["body-text-4"]}>
          <div>
            {questionIndex === 0 && (
              <div style={{ borderBottom: "1px solid #e0e1e3", display: "flex" }}>
                <div className={styles["page-title-description-4"]}>
                  <div>
                    <h1 className={styles["page-first-title"]}>{displayTitle}</h1>
                    <div className={styles["description-level"]}></div>
                    <p>
                      <b>{overAllScoreOutcome.title}</b>
                    </p>
                    <p>{overAllScoreOutcome.text}</p>
                    <div className={styles["description-img-container"]}>
                      {categoryScore === 1 && (
                        <img
                          className={styles["description-img"]}
                          src="https://www.imprivata.com/sites/imprivata/files/2022-08/01_ranking_icon.png"
                        />
                      )}
                      {categoryScore === 2 && (
                        <img
                          className={styles["description-img"]}
                          src="https://www.imprivata.com/sites/imprivata/files/2022-08/02_ranking_icon.png"
                        />
                      )}
                      {categoryScore === 3 && (
                        <img
                          className={styles["description-img"]}
                          src="https://www.imprivata.com/sites/imprivata/files/2022-08/03_ranking_icon.png"
                        />
                      )}
                      {categoryScore === 4 && (
                        <img
                          className={styles["description-img"]}
                          src="https://www.imprivata.com/sites/imprivata/files/2022-08/04_ranking_icon.png"
                        />
                      )}
                      {categoryScore === 5 && (
                        <img
                          className={styles["description-img"]}
                          src="https://www.imprivata.com/sites/imprivata/files/2022-08/05_ranking_icon.png"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {questionIndex > 0 && (
              <div
                className={`${styles["page-title-description-4"]} ${styles["page-title-description-4-secondary"]}`}
                style={{ color: "#747575", fontSize: "12px" }}
              >
                <div>{title.toUpperCase()}</div>
              </div>
            )}

            <div className={styles["question-results"]}>
              <div className={styles["question-results__section"]}>

                <div className={styles["question-results__question"]}>
                  <p>
                    <b style={{ fontSize: "18px" }}>Question</b>
                  </p>
                  <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: question.text }} />
                  <p>
                    <b style={{ fontSize: "18px"}}>Answer</b>
                  </p>
                  <p style={{ fontSize: "14px", paddingBottom:'20px'  }}>{question.answer.text}</p>
                </div>
                <div className={styles["question-results-img"]}>
                  {question.answer.score === 1 && (
                      <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/01_ranking_icon.png" />
                  )}
                  {question.answer.score === 2 && (
                    <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/02_ranking_icon.png" />
                  )}
                  {question.answer.score === 3 && (
                    <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/03_ranking_icon.png" />
                  )}
                  {question.answer.score === 4 && (
                    <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/04_ranking_icon.png" />
                  )}
                  {question.answer.score === 5 && (
                    <img src="https://www.imprivata.com/sites/imprivata/files/2022-08/05_ranking_icon.png" />
                  )}
                  <p style={{paddingLeft:'20px'}}>
                    <b style={{ fontSize: "18px" }}>{`Your level: ${
                      getOutcomeText(question.id, question.answer.score).title.split("-")[0]
                    }`}</b>
                    <p style={{ paddingBottom: "1rem" }}>
                      {getOutcomeText(question.id, question.answer.score).title.split("-")[1].toUpperCase()}
                    </p>
                  </p>
                </div>
                <div className={styles["question-results__level"]}>
                  <div>
                    <p
                      className={styles["text"]}
                      style={{ textAlign: "left" }}
                      dangerouslySetInnerHTML={{ __html: getOutcomeText(question.id, question.answer.score).text }}
                    >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["page-number"]} style={{ color: "black" }}>
                  {pageNumber}
                </div>
        </div>
      </div>
    </div>
  );
}
