import { IdentityAssessmentCustomerData, IdentityAssessment } from "../types/identityAssessment.types";
import { BLOCKLIST } from "../../../utils/blockList";

export class ValidationService {
  static IsValid(identityAssessment: IdentityAssessment, model: IdentityAssessmentCustomerData): boolean {
    let result: string[] = [];
    identityAssessment.customFields.forEach((field) => {
      if ((field.fieldName === "email" && !this.IsValidEmail(model.email)) ||  (field.fieldName === "email" && this.isEmailInBlockList(model.email)) || model.email.length > 100)
        result.push(field.fieldName);
      if (
        !["email", "consent"].includes(field.fieldName) &&
        (!model[field.fieldName].length || model[field.fieldName].length > 50)
      )
        result.push(field.fieldName);
    });
    let finalResult = result.length ? false : true;
    return finalResult;
  }

  static IsValidEmail(email: string): boolean {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(email);
    return result;
  }

  static isEmailInBlockList(email: string): boolean {
    const blockListExpression: RegExp = RegExp(BLOCKLIST.join("|"), "gi");
    const blocklistResults: boolean = blockListExpression.test(email);
    return blocklistResults;
  }
}