import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";
import { generatePdf, getContent } from "./thunks/getContent.thunks";
import {
  IdentityAssessment,
  QuestionAnsware,
  QuestionyResult,
  IdentityAssessmentError,
} from "./types/identityAssessment.types";
import { ValidationService } from "./services/identityAssessment.validation.service";

export interface IdentityAssessmentResponse {
  data?: IdentityAssessment;
  status: "idle" | "loading" | "success" | "failed";
  generatePdfStatus: "idle" | "loading" | "success" | "failed";
  currentStep: number;
  isStarted: boolean;
  segment:string;
  excludeQuestionId: number,
  pdf:any,
  pdfName:string
}

const initialState: IdentityAssessmentResponse = {
  data: undefined,
  status: "idle",
  generatePdfStatus:'idle',
  isStarted: false,
  currentStep: 0,
  segment: '',
  excludeQuestionId: 0,
  pdf:'',
  pdfName:''

};

export const identityAssessment = createSlice({
  name: "identityAssessment",
  initialState,
  reducers: {
    saveCustomerData: (state, action: any) => {
      Object.keys(action.payload).map(key => {
        state.data!.result.customerData[key] = action.payload[key]
      })
    },
    startAssessment: (state) => {
      var isValidCustomerData = ValidationService.IsValid(
        state.data!, state.data?.result.customerData!
      );
      if (!isValidCustomerData) {
        state.data!.error.firstPageError = true;
        return;
      }

      state.data!.error.firstPageError = false;
      state.isStarted = true;
      state.currentStep = 1;
    },

    nextStep: (state, action: any) => {
      if(action.payload.answers.length) {
        const selectAsnwer = state.data!.result.results.find(
          (x) => x.questonId == action.payload.id
        );
        if(selectAsnwer) state.data!.error.questionError = false;

        if (!selectAsnwer) {
          state.data!.error.questionError = true;
          return;
        }
      }

      state.data!.error.questionError = true;
      state.currentStep++;
    },
    prevStep: (state) => {
      state.data!.error.questionError = false;
      state.currentStep--;
    },
    saveAnswer: (state, action: any) => {
      const existingAnsware = state.data!.result.results.find(
        (x) => x.questonId == action.payload.questionId
      );
      if (!existingAnsware) {
        const questionResult: QuestionyResult = {
          questonId: action.payload.questionId,
          answareId: action.payload.id,
        };
        state.data!.error.questionError = false;
        state.data!.result.results.push(questionResult);
        if(action.payload.isGeneralQuestion) {
          if(action.payload.questionIdentifier === 'Gq1') state.data!.result.generalQuestionsResults.title = action.payload.text
          if(action.payload.questionIdentifier === 'Gq2') state.data!.result.generalQuestionsResults.employees = action.payload.text
        }
        return;
      }
      existingAnsware.answareId = action.payload.id;
      if(action.payload.isGeneralQuestion) {
        if(action.payload.questionIdentifier === 'Gq1') state.data!.result.generalQuestionsResults.title = action.payload.text
        if(action.payload.questionIdentifier === 'Gq2') state.data!.result.generalQuestionsResults.employees = action.payload.text
      }
    },

    saveSegmentSelector: (state, action:any) => {
      state.segment = action.payload.segment_selector.toLowerCase()
      state.excludeQuestionId = action.payload.exclude_question_id
    },

    saveResults: (state, action:any) => {
      state.data!.result.categoryResults = action.payload.categoriesScore
      state.data!.result.generalScore = action.payload.generalScore
      state.generatePdfStatus = 'loading'
    },
    
    saveError: (state, action:any) => {
      state.data!.error[action.payload.name] = action.payload.value
    },

    setCategories:(state, action:any) => {
      state.data!.categories = action.payload
    },	

    clearResults: (state) => {	
      state.data!.result.results=[]	
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getContent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContent.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getContent.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(generatePdf.pending, (state) => {
        state.generatePdfStatus = "loading";
      })
      .addCase(generatePdf.fulfilled, (state, action) => {
        state.generatePdfStatus = "success";
        state.pdf = action.payload?.link;
        state.pdfName = action.payload?.fileName;
      })
      .addCase(generatePdf.rejected, (state) => {
        state.generatePdfStatus = "failed";
      });
      
  },
});

export const {
  saveAnswer,
  saveCustomerData,
  startAssessment,
  nextStep,
  prevStep,
  saveSegmentSelector,
  saveResults,
  saveError,
  setCategories,
  clearResults
} = identityAssessment.actions;

export const selectIdentityAssessment = (state: RootState) =>
  state.identityAssessment;

export default identityAssessment.reducer;
